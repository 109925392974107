import axios from 'axios';

import { APISession, CalendarUpcomingEvents } from '../Contexts.model';
import {
	Avatar,
	BIO,
	CalendarEventType,
	Challenge,
	MicroLearning,
	Mission,
	Notification,
	Team,
	TeamMember,
	User,
	UserHistory
} from '../Contexts.types';

import DefaultTeamLogo from '../../assets/elements2D/DefaultTeamLogo.svg';
import {
	default as DefaultThumbnail,
	default as PlaceHolderCMAShips
} from '../../assets/elements2D/thumbnail_placeholder.jpg';

export const APIGetSession = async (
	userId: number
): Promise<{
	currentSession: APISession;
	success: boolean;
}> => {
	try {
		const response = await axios.get('/api/back-end/completedSessions/' + userId);
		const data = response.data;

		// Process the data and transform it into the desired object structure
		const transformedData: APISession = {
			completion: data.completion,
			sessionId: data.sessionId,
			startDate: data.startDate,
			endDate: data.endDate
		};

		return {
			currentSession: transformedData,
			success: true
		};
	} catch (error) {
		console.error(error);
		return {
			currentSession: {} as APISession,
			success: false
		};
	}
};

export const fetchProfileImage = async (item: any): Promise<string> => {
	let imageSource = DefaultTeamLogo;

	if (item.filePath && item.filePath !== null && item.filePath !== '') {
		try {
			const result = await axios.get('/api/download/' + item.filePath);

			return result.data;
		} catch (error) {
			console.error(error);
		}
	}

	return imageSource;
};

export const APIgetLeaderboardTeams = async (
	_sessionId: number
): Promise<{
	teams: Team[];
	success: boolean;
}> => {
	try {
		if (!_sessionId) return { teams: [], success: false };

		const response = await axios.get('/api/back-end/leaderboards/teams', {
			params: { sessionId: _sessionId }
		});
		const data = response.data;

		const transformedDataPromises = data.leaderBoardProfiles.map(async (item: any) => {
			const base64 = await fetchProfileImage(item);

			return {
				id: item.id,
				name: item.name,
				rank: item.rank,
				score: item.score,
				uploadDate: new Date(item.uploadDate),
				uploadedBy: item.uploadedBy,
				fileName: item.fileName,
				filePath: item.filePath,
				fileType: item.fileType,
				scanStatus: item.scanStatus,
				base64: base64
			};
		});

		const transformedData = await Promise.all(transformedDataPromises);

		return {
			teams: transformedData,
			success: true
		};
	} catch (error) {
		console.error(error);
		return {
			teams: [],
			success: false
		};
	}
};

export const APIGetProfile = async (
	individualId: number
): Promise<{
	user: User | null;
	success: boolean;
}> => {
	try {
		if (!individualId)
			return {
				user: null,
				success: false
			};

		const response = await axios.get(`/api/back-end/profiles/${individualId}`);
		const data = response.data;

		// Process the data and transform it into the desired object structure
		const transformedData: User = {
			id: data.id, // TODO: check with BRT id is not right for id 46 => 166
			teamId: data.teamId,
			name: data.name,
			avatar: {
				color: data.avatar.color,
				accessoryName: data.avatar.accessoryName
			},
			BIO: {
				motto: data.bio.motto,
				amazing: data.bio.amazing,
				trust: data.bio.trust,
				improve: data.bio.improve
			},
			score: data.score,
			rank: data.rank,

			history: {} as UserHistory,

			homeFlag: data.homeFlag || false,
			practiceFlag: data.practiceFlag || false,
			profileFlag: data.profileFlag || false,
			introductionFlag: data.introductionFlag || false,
			challengesFlag: data.challengesFlag || false,
			microlearningFlag: data.microlearningFlag || false,
			missionsFlag: data.missionsFlag || false
		};

		return {
			user: transformedData,
			success: true
		};
	} catch (error) {
		console.error(error);
		return {
			user: null,
			success: false
		};
	}
};

export const APIGetTeam = async (
	teamId: number
): Promise<{
	team: Team | null;
	success: boolean;
}> => {
	try {
		const response = await axios.get(`/api/back-end/profiles/teams/${teamId}`);
		const data = response.data;

		const base64 = await fetchProfileImage(data);

		// Process the data and transform it into the desired object structure
		const transformedData: Team = {
			id: data.id,
			name: data.name,
			score: data.score,
			fileName: data.fileName,
			filePath: data.filePath,
			fileType: data.fileType,
			scanStatus: data.scanStatus,
			uploadDate: data.uploadDate,
			uploadedBy: data.uploadedBy,
			rank: data.rank,
			base64: base64,
			members: data.members.map((memberData: any) => {
				const member: TeamMember = {
					id: memberData.id,
					name: memberData.name,
					teamId: memberData.teamId,
					score: memberData.score,
					rank: memberData.rank,
					avatar: {
						color: memberData.avatar.color,
						accessoryName: memberData.avatar.accessoryName
					},
					bio: {
						motto: memberData.bio.motto,
						amazing: memberData.bio.amazing,
						trust: memberData.bio.trust,
						improve: memberData.bio.improve
					}
				};
				return member;
			})
		};

		return {
			team: transformedData,
			success: true
		};
	} catch (error) {
		console.error(error);
		return {
			team: null,
			success: false
		};
	}
};

export const APIGetHistory = async (
	individualId: number,
	sessionId: number
): Promise<{
	userHistory: UserHistory;
	success: boolean;
}> => {
	try {
		if (
			individualId === null ||
			individualId === undefined ||
			individualId === 0 ||
			sessionId === null ||
			sessionId === undefined ||
			sessionId === 0
		) {
			return {
				userHistory: {} as UserHistory,
				success: false
			};
		}
		const response = await axios.get(`/api/back-end/profiles/histories`, {
			params: { individualId, sessionId }
		});

		const data = response.data;

		// Process the data and transform it into the desired object structure
		const transformedData: UserHistory = {
			missions: data.missions.map((item: any) => {
				const endDate = Date.parse(item.endDate);
				// set end of day
				const endDateWithTime = new Date(endDate).setHours(23, 59, 59, 999);

				return {
					id: item.id,
					name: item.name,
					score: item.score,
					durationInMinutes: item.durationInMinutes,
					sessionId: item.sessionId,
					startDate: item.startDate,
					endDate: endDateWithTime
				};
			}),

			challenges: data.challenges
				.map((item: any) => {
					const endDate = Date.parse(item.endDate);
					// set end of day
					const endDateWithTime = new Date(endDate).setHours(23, 59, 59, 999);

					return {
						id: Number(item.id),
						name: item.name,
						challengeType: item.challengeType.toLowerCase(),
						complexity: item.complexity.toLowerCase(),
						topic: item.topic,
						maxPoints: Number(item.maxPoints),
						instructions: item.instructions,
						announcement: item.announcement,
						deliverables: item.deliverables,
						successCondition: item.successCondition,
						evaluationCriteria: item.evaluationCriteria,
						startDate: Date.parse(item.startDate),
						endDate: endDateWithTime,
						sessionId: Number(item.sessionId),
						mediaFormats: item.mediaFormats,
						score: item.score
					};
				})
				.filter((e: Challenge) => e.score !== null && e.score !== 0)
		};

		return {
			userHistory: transformedData,
			success: true
		};
	} catch (error) {
		console.error(error);
		return {
			userHistory: {} as UserHistory,
			success: false
		};
	}
};

export interface UpdatingProfile {
	avatar: Avatar;
	bio: BIO;
	teamId: number;
	userID: number;
	homeFlag?: boolean;
	practiceFlag?: boolean;
	profileFlag?: boolean;
	introductionFlag?: boolean;
	challengesFlag?: boolean;
	microlearningFlag?: boolean;
	missionsFlag?: boolean;
}

export const APIUpdateProfile = async (updatingProfile: UpdatingProfile): Promise<boolean> => {
	try {
		if (!updatingProfile.userID || !updatingProfile.teamId) {
			console.error('Error posting profile not found');
			return false;
		}

		const paramsToStr = JSON.stringify({ updatingProfile });
		const response = await axios.put('/api/back-end/profiles', paramsToStr, {
			headers: {
				'Content-Type': ['application/json']
			}
		});
		// status code 200 or 201
		if (response.status !== 200 && response.status !== 201) {
			return false;
		}

		return true;
	} catch (error) {
		console.error('Error posting profile:', error);
		// Handle error if necessary
		return false;
	}
};

export const APIgetLeaderboardUsers = async (
	_sessionId: number
): Promise<{
	users: User[];
	success: boolean;
}> => {
	try {
		if (!_sessionId) return { users: [], success: false };

		const response = await axios.get('/api/back-end/leaderboards/individuals', {
			params: { sessionId: _sessionId }
		});

		const data = response.data;

		const transformedData: User[] = data.leaderBoardProfiles.map((item: any) => ({
			id: item.id,
			name: item.name,
			avatar: {
				color: item.avatar.color,
				accessoryName: item.avatar.accessoryName
			},
			score: item.score,
			rank: item.rank,
			history: []
		}));

		return {
			users: transformedData,
			success: true
		};
	} catch (error) {
		console.error(error);
		return {
			users: [],
			success: false
		};
	}
};

export const APIGetAllChallenges = async (
	sessionId: number
): Promise<{
	challenges: Challenge[];
	success: boolean;
}> => {
	try {
		const response = await axios.get(`/api/back-end/challenges?sessionId=${sessionId}`);
		const data = response.data;

		// Process the data and transform it into the desired object structure
		const transformedData: Challenge[] = data.map((item: any) => {
			const endDate = Date.parse(item.endDate);

			return {
				id: Number(item.id),
				name: item.name,
				challengeType: item.challengeType.toLowerCase(),
				complexity: item.complexity.toLowerCase(),
				topic: item.topic,
				maxPoints: Number(item.maxPoints),
				instructions: item.instructions,
				announcement: item.announcement,
				deliverables: item.deliverables,
				successCondition: item.successCondition,
				evaluationCriteria: item.evaluationCriteria,
				startDate: Date.parse(item.startDate),
				endDate: endDate,
				status: item.status,
				sessionId: Number(item.sessionId),
				mediaFormats: item.mediaFormats
			};
		});

		return {
			challenges: transformedData,
			success: true
		};
	} catch (error) {
		console.error(error);
		return {
			challenges: [],
			success: false
		};
	}
};

export const APIGetChallengeSubmissionStatus = async (
	challengeId: number,
	individualId: number,
	teamId: number
): Promise<{
	submitted: boolean;
	success: boolean;
}> => {
	try {
		const response = await axios.get('/api/back-end/challenges/submits', {
			params: { challengeId, individualId, teamId },
			headers: {
				'Content-Type': ['application/json', 'charset=UTF-8']
			}
		});
		const data = response.data;

		return {
			submitted: data,
			success: true
		};
	} catch (error) {
		console.error(error);
		return {
			submitted: false,
			success: false
		};
	}
};

export const APIPostChallenge = async (requestData: {
	challengeId: number;
	fileName: string;
	filePath: string;
	fileType: string;
	memberId: number;
	teamId: number;
	scanStatus?: string;
}): Promise<{ success: boolean }> => {
	try {
		requestData.scanStatus = 'PENDING';

		const response = await axios.post('/api/back-end/challenges/submissions', requestData);

		// check status code
		if (response.status !== 200) {
			return {
				success: false
			};
		}

		return {
			success: true
		};
	} catch (error) {
		console.error(error);
		return {
			success: false
		};
	}
};

export const APIGetMissions = async (
	individualId: number,
	sessionId: number
): Promise<{ missions: Mission[]; success: boolean }> => {
	try {
		const missionsResponse = await axios.get(`/api/back-end/missions?sessionId=${sessionId}`);

		const missionData = missionsResponse.data;

		const historyResponse = await APIGetHistory(individualId, sessionId);
		if (!historyResponse.success) {
			return {
				missions: [],
				success: false
			};
		}

		// Process the data and transform it into the desired object structure
		const transformedData: Mission[] = missionData.map((item: any) => ({
			id: item.id,
			name: item.name,
			durationInMinutes: item.durationInMinutes,
			startDate: new Date(item.startDate).getTime(),
			endDate: new Date(item.endDate).getTime(),
			sessionId: item.sessionId,
			displayName: item.displayName
		}));

		// update transformedData score from user history
		for (let i = 0; i < transformedData.length; i++) {
			const mission = transformedData[i];
			historyResponse.userHistory.missions.forEach((item) => {
				if (item.id === mission.id) {
					transformedData[i].score = item.score;
				}
			});
		}

		return {
			missions: transformedData,
			success: true
		};
	} catch (error) {
		console.error(error);
		return {
			missions: [],
			success: false
		};
	}
};

export const APIPostMission = async (
	individualId: number,
	missionId: number,
	score: number
): Promise<{ success: boolean }> => {
	try {
		const response = await axios.post('/api/back-end/missions/submissions', {
			indiviualId: individualId,
			missionId,
			score
		});

		if (response.status !== 200) {
			return {
				success: false
			};
		}

		return {
			success: true
		};
	} catch (error) {
		console.error(error);
		return {
			success: false
		};
	}
};

const fetchImage = async (link: string, externalLink: boolean) => {
	try {
		if (externalLink) return link;
		else {
			return await fetchProfileImage({
				filePath: link
			});
		}
	} catch (error) {
		// Handle any errors here
		console.error('Error fetching image:', error);

		return DefaultThumbnail;
	}
};

export const APIGetMicroLearnings = async (
	sessionId: number
): Promise<{
	microLearnings: MicroLearning[];
	success: boolean;
}> => {
	try {
		const response = await axios.get(`/api/back-end/oldMiscellaneous?sessionId=${sessionId}`);
		const data = response.data;

		const transformedDataPromises = data.map(async (item: MicroLearning) => {
			const base64 = await fetchImage(item.thumbnailLink, item.externalThumbnailLink);

			return {
				id: item.id,
				type: item.miscellaneousType.toLowerCase(),
				title: item.name,
				thumbnailLink: item.thumbnailLink ? item.thumbnailLink : PlaceHolderCMAShips,
				link: item.link,
				externalThumbnailLink: item.externalThumbnailLink,
				externalLink: item.externalLink,
				shortDescription:
					item?.description?.length > 120
						? item?.description?.substring(0, 120) + '...'
						: item?.description,
				description: item.description,
				base64: base64
			};
		});

		const transformedData = await Promise.all(transformedDataPromises);

		return {
			microLearnings: transformedData,
			success: true
		};
	} catch (error) {
		console.error(error);
		return {
			microLearnings: [],
			success: false
		};
	}
};

export const APIGetCalendar = async (): Promise<{
	calendar: CalendarUpcomingEvents;
	success: boolean;
}> => {
	try {
		const response = await axios.get('/api/back-end/calendars');
		const data = response.data;

		// Process the data and transform it into the desired object structure
		const transformedData: CalendarUpcomingEvents = {
			LearningEvents:
				data.microlearnings?.map((item: any) => ({
					type: CalendarEventType.LEARNING,
					title: item.title
				})) || [],
			ChallengesEvents:
				data.challenges?.map((item: any) => ({
					type: CalendarEventType.CHALLENGE,
					title: item.title
				})) || [],
			PracticesEvents:
				data.practices?.map((item: any) => ({
					type: CalendarEventType.PRACTICE,
					title: item.title
				})) || [],
			MissionEvents:
				data.missions?.map((item: any) => ({
					type: CalendarEventType.MISSION,
					title: item.title
				})) || []
		};

		return {
			calendar: transformedData,
			success: true
		};
	} catch (error) {
		console.error(error);
		return {
			calendar: {
				LearningEvents: [],
				ChallengesEvents: [],
				PracticesEvents: [],
				MissionEvents: []
			},
			success: false
		};
	}
};

export const APIGetNotifications = async (
	individualId: number,
	read: boolean,
	range?: string
): Promise<{
	notifications: Notification[];
	success: boolean;
}> => {
	try {
		if (!individualId)
			return {
				notifications: [],
				success: false
			};

		const params: Record<string, string> = {
			individualId: individualId?.toString(),
			range: range ? range : '0-10',
			read: read.toString()
		};

		const response = await axios.get('/api/back-end/notifications', {
			params
		});

		const data = response.data;

		// Process the data and transform it into the desired object structure
		const transformedData: Notification[] = data.map((item: any) => ({
			id: item.id,
			title: item.title,
			description: item.description,
			read: item.read,
			createdDate: new Date(item.createdDate),
			updatedDate: new Date(item.updatedDate),
			userId: item.userId,
			challengeId: item.challengeId,
			isBonus: item.isBonus,
			bonusType: item.bonusType
		}));

		return {
			notifications: transformedData,
			success: true
		};
	} catch (error) {
		console.error(error);
		return {
			notifications: [],
			success: false
		};
	}
};

export const APIPostNotificationAsShown = async (id: number): Promise<boolean> => {
	try {
		const response = await axios.put(`/api/back-end/notifications/${id}`);

		if (response.status >= 400) {
			return false;
		}

		return true;
	} catch (error) {
		console.error('Error posting notification:', error);
		// Handle error if necessary
		return false;
	}
};

export const APIUploadFile = async (body: {
	file: File;
	name: string; // Name of the file
	label: string;
}): Promise<{ success: boolean }> => {
	try {
		// Convert file to base64
		const base64 = await fileToBase64(body.file);

		const response = await axios.post('/api/upload/', {
			image: base64,
			name: body.name,
			label: body.label
		});

		if (response.status >= 400) {
			return {
				success: false
			};
		}

		return {
			success: true
		};
	} catch (error) {
		console.error(error);
		return {
			success: false
		};
	}
};

export const APIFetchVideo = async (
	videoId: string
): Promise<{
	videoUrl: string;
	videoBlob: Blob;
	success: boolean;
}> => {
	try {
		// Remove consecutive slashes and set the path state
		const newPath = `/api/video/${videoId}`.replace(/\/{2,}/g, '/');

		const response = await axios.get(newPath);

		if (!response.data || response.status >= 500) {
			console.error('Failed to fetch video data.');
			return {
				videoUrl: '',
				videoBlob: {} as Blob,
				success: false
			};
		}

		const videoBlob = new Blob([response.data], { type: 'video/mp4' });

		const videoUrl = URL.createObjectURL(videoBlob);

		return {
			videoUrl,
			videoBlob,
			success: true
		};
	} catch (error) {
		console.error('Error fetching video:', error);
		return {
			videoUrl: '',
			videoBlob: {} as Blob,
			success: false
		};
	}
};

const fileToBase64 = (file: File): Promise<string> => {
	return new Promise<string>((resolve, reject) => {
		const reader = new FileReader();

		reader.onload = () => {
			if (reader.result) {
				const base64 = reader.result.toString();
				resolve(base64);
			} else {
				reject(new Error('Failed to convert file to Base64.'));
			}
		};

		reader.onerror = (error) => {
			reject(error);
		};

		reader.readAsDataURL(file);
	});
};
