import { Box, CircularProgress, Divider, Grid, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import earthIcons from '../../assets/elements2D/earthIcons.svg';
import { ChallengeCard } from '../../components/ChallengeCard';
import { Timer } from '../../components/Timer';
import { CornerContainer } from '../../components/containers/CornerContainer';
import { Header } from '../../components/page/Header';
import { Navbar } from '../../components/page/Navbar';
import { ScrollablePage } from '../../components/page/ScrollablePage';
import { APIContext } from '../../contexts/API/APIContext';
import { GlobalContext } from '../../contexts/GlobalContext';
import { UIContext } from '../../contexts/UIContext';

export const ChallengeHUBPage = () => {
	const UIContextValues = useContext(UIContext);
	const GlobalContextValues = useContext(GlobalContext);
	const APIContextValues = useContext(APIContext);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const { t } = useTranslation();

	const [maxPoints, setMaxPoints] = useState<number>(0);

	useEffect(() => {
		const _maxPoints = GlobalContextValues.availableChallenges?.reduce(
			(acc, challenge) => acc + challenge.maxPoints,
			0
		);

		setMaxPoints(_maxPoints || 0);
	}, [GlobalContextValues?.availableChallenges]);

	useEffect(() => {
		UIContextValues.checkOverlaysEventsCompletion?.('challenges');

		const init = async () => {
			setIsLoading(true);
			await APIContextValues.getAllChallenges();
			setIsLoading(false);
		};

		init();
	}, []);

	return (
		<>
			<Header title={t('challenges_HUB_header_title')}></Header>
			<Box component={'div'} sx={challengeStyles.earth}></Box>
			<ScrollablePage>
				<Grid container>
					<Box sx={challengeStyles.containerChallengesAvailables}>
						<CornerContainer>
							<Box sx={{ padding: '3vh' }}>
								<Typography
									variant="h2"
									sx={challengeStyles.containerChallengesTitle}
								>
									{t('challenges_HUB_subtitle_available')}
								</Typography>
								<Box sx={challengeStyles.containerDivider}>
									<Divider
										variant="middle"
										light={true}
										sx={challengeStyles.divider}
									/>
								</Box>
								<Typography sx={challengeStyles.subText}>
									{t('challenges_HUB_text_available', { count: maxPoints })}
								</Typography>

								<Timer
									uppercase={true}
									endTime={
										GlobalContextValues.availableChallenges?.[0]?.endDate || 0
									}
								></Timer>
								<Stack spacing={3}>
									{!isLoading ? (
										GlobalContextValues.availableChallenges?.map(
											(challenge) => {
												return (
													<ChallengeCard
														key={`available_challenge_${challenge.id}`}
														challenge={challenge}
													/>
												);
											}
										)
									) : (
										<CircularProgress
											sx={{ alignSelf: 'center' }}
										></CircularProgress>
									)}

									{GlobalContextValues.availableChallenges?.length === 0 && (
										<Typography
											sx={challengeStyles.errorMessage}
											variant="body1"
										>
											{t('challenges_HUB_popup_error')}
										</Typography>
									)}
								</Stack>
							</Box>
						</CornerContainer>
					</Box>
					<Box sx={challengeStyles.containerChallengesUpcoming}>
						<CornerContainer>
							<Box sx={{ padding: '2vh' }}>
								<Typography
									variant="h2"
									sx={challengeStyles.containerChallengesTitle}
								>
									{t('challenges_HUB_subtitle_upcoming')}
								</Typography>
								<Box sx={challengeStyles.containerDivider}>
									<Divider
										variant="middle"
										light={true}
										sx={challengeStyles.divider}
									/>
								</Box>
								<Typography sx={challengeStyles.subText}>
									{t('challenges_HUB_text_upcoming')}{' '}
								</Typography>

								<Stack spacing={3}>
									{!isLoading ? (
										GlobalContextValues.upcomingChallenges?.map((challenge) => {
											return (
												<ChallengeCard
													key={`upcoming_challenge_${challenge.id}`}
													challenge={challenge}
												/>
											);
										})
									) : (
										<CircularProgress
											sx={{ alignSelf: 'center' }}
										></CircularProgress>
									)}
								</Stack>

								{GlobalContextValues.upcomingChallenges?.length === 0 && (
									<Typography sx={challengeStyles.errorMessage} variant="body1">
										{t('challenges_HUB_popup_error')}
									</Typography>
								)}
							</Box>
						</CornerContainer>
					</Box>
				</Grid>
			</ScrollablePage>
			<Navbar></Navbar>
		</>
	);
};
export const challengeStyles = {
	mainText: {},
	cornerContainer: {
		marginTop: '4em',
		display: 'flex',
		alignItems: 'stretch'
	},

	earth: {
		position: 'absolute',
		bottom: '-100px',
		left: '60%',
		backgroundImage: `url(${earthIcons})`,
		transform: 'rotate(280deg)',
		height: '500px',
		width: '500px',
		outline: 'none',
		borderColor: '#0BE3CD',
		boxShadow: '0 0 70px #0BE3CD',
		borderRadius: '100%'
	},
	containerChallengesAvailables: {
		marginTop: '2vh',
		width: '100%',
		// height: '35vh',
		textAlign: 'center'
	},
	containerChallengesUpcoming: {
		textAlign: 'center',
		marginTop: '2vh',
		width: '100%'
		// height: '20vh'
	},
	containerChallengesTitle: {
		textAlign: 'center',
		marginBottom: '1vh'
	},
	challengeContent: {
		padding: '3em'
	},
	divider: {
		borderColor: 'error.main'
	},
	subText: {
		marginBottom: '1em'
	},
	containerDivider: {
		// display: 'flex',
		// justifyContent: 'center',
		// alignItems: 'flex-end',
		height: '1em',
		width: '100%'
		// backgroundColor: 'cyan'
	},
	errorMessage: {
		textAlign: 'center',
		marginTop: '1em',
		color: 'error.main'
	}
};
