// import axios from 'axios';
import * as React from 'react';
import { createContext, FC, PropsWithChildren } from 'react';
import { APIContext } from './API/APIContext';
import { APIGetChallengeSubmissionStatus, APIGetHistory } from './API/APIFechers';
import { GlobalContextInterface } from './Contexts.model';
import {
	Challenge,
	ChallengeStatus,
	MicroLearning,
	Team,
	User,
	UserHistory
} from './Contexts.types';

export const GlobalContext = createContext<GlobalContextInterface>(undefined!);

export const GlobalProvider: FC<PropsWithChildren> = ({ children }) => {
	const APIContextValues = React.useContext(APIContext);

	const [introductionVideoUrl, setIntroductionVideoUrl] = React.useState<string>(
		'/api/video/theme_videos_CMA_CGM_Explore_Introduction_V2C.mp4'
	);

	const [endingSummaryVideoUrl, setEndingSummaryVideoUrl] = React.useState<string>(
		'/api/video/theme_videos_CMA_CGM_Explore_conclusion_V1C.mp4'
	);

	const [usersLeaderboard, setUsersLeaderboard] = React.useState<User[]>([]);

	const [teamsLeaderboard, setTeamsLeaderboard] = React.useState<Team[]>([]);

	const [availableChallenges, setAvailableChallenges] = React.useState<Challenge[]>([]);

	const [upcomingChallenges, setUpcomingChallenges] = React.useState<Challenge[]>([]);

	const [microLearning, setMicroLearning] = React.useState<MicroLearning[]>([]);

	React.useEffect(() => {
		setTeamsLeaderboard(APIContextValues.teamsLeaderboard);
	}, [APIContextValues.teamsLeaderboard]);

	React.useEffect(() => {
		setUsersLeaderboard(APIContextValues.usersLeaderboard);
	}, [APIContextValues.usersLeaderboard]);

	React.useEffect(() => {
		setMicroLearning(APIContextValues.microLearnings);
	}, [APIContextValues.microLearnings]);

	React.useEffect(() => {
		const init = async () => {
			// Assuming APIContextValues and APIGetChallengeSubmissionStatus are defined somewhere in your codebase
			// You can import and use them as per your actual implementation.

			try {
				const { availableChallenges, upcomingChallenges } =
					filterAvailableAndUpcomingChallenges(APIContextValues.allChallenges);

				const challengesWithSubmissionStatus =
					await updateChallengeStatusWithSubmissionStatus(availableChallenges);

				const challengesWithScore: Challenge[] = await updateChallengesScores(
					challengesWithSubmissionStatus
				);

				setAvailableChallenges(challengesWithScore);
				setUpcomingChallenges(upcomingChallenges);
			} catch (error) {
				console.error('Error initializing challenges:', error);
			}
		};
		init();
	}, [APIContextValues.allChallenges]);

	const filterAvailableAndUpcomingChallenges = (allChallenges: any[]) => {
		const now = Date.now();

		const availableChallenges = allChallenges
			.filter((challenge: { status: string }) => {
				return challenge.status === 'STARTED';
			})
			.sort((a, b) => a.endDate - b.endDate);

		const upcomingChallenges = allChallenges
			.filter((challenge: { startDate: number }) => challenge.startDate > now)
			.map((challenge) => ({
				...challenge,
				status: ChallengeStatus.UPCOMING
			}))
			.sort((a, b) => a.startDate - b.startDate)
			.slice(0, 3);

		return { availableChallenges, upcomingChallenges };
	};

	const updateChallengeStatusWithSubmissionStatus = async (challenges: any) => {
		const updatedChallenges = [];
		for (const challenge of challenges) {
			const submissionStatus = await APIGetChallengeSubmissionStatus(
				challenge.id,
				APIContextValues.myProfile.id,
				APIContextValues.myProfile.teamId
			);

			challenge.status = submissionStatus.submitted
				? ChallengeStatus.SUBMITTED
				: ChallengeStatus.AVAILABLE;

			updatedChallenges.push(challenge);
		}
		return updatedChallenges;
	};

	const updateChallengesScores = async (challenges: any[]) => {
		const historyResponse: {
			userHistory: UserHistory;
			success: boolean;
		} = await APIGetHistory(
			APIContextValues.myProfile.id,
			APIContextValues.currentSession.sessionId
		);

		const updatedChallenges = challenges.map((challenge: Challenge) => {
			const userHistoryChallenge = historyResponse?.userHistory?.challenges?.find(
				(historyChallenge: { id: any }) => historyChallenge.id === challenge.id
			);

			if (userHistoryChallenge) {
				challenge.score = userHistoryChallenge.score;
			}

			return challenge;
		});

		return updatedChallenges;
	};

	return (
		<GlobalContext.Provider
			value={{
				introductionVideoUrl,
				endingSummaryVideoUrl,
				usersLeaderboard,
				setUsersLeaderboard,
				teamsLeaderboard,
				setTeamsLeaderboard,

				availableChallenges,
				upcomingChallenges,

				microLearning,
				setMicroLearning,
				setIntroductionVideoUrl,
				setEndingSummaryVideoUrl,
				setAvailableChallenges,
				setUpcomingChallenges
			}}
		>
			{children}
		</GlobalContext.Provider>
	);
};
