import { Grid, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useSound from 'use-sound';
import podium from '../assets/leaderboard/podium.svg';
import clickButtonSfx from '../assets/sounds/button-click.mp3';
import { LeaderboardRank } from '../components/LeaderboardRank';
import { ContentDisplayEnum } from '../components/containers/CornerContainer';
import { StyledTab, TabPanel } from '../components/containers/TabPanel';
import { APIContext } from '../contexts/API/APIContext';
import { APIGetProfile } from '../contexts/API/APIFechers';
import { GlobalContext } from '../contexts/GlobalContext';
import { ParametersContext } from '../contexts/ParametersContext';
import { UIContext } from '../contexts/UIContext';
import { UserContext } from '../contexts/UserContext';
import { PopupContainer } from './PopupContainer';
import { UserInfos } from '../contexts/Contexts.types';
import { useLocalStorage } from 'usehooks-ts';

export const LeaderboardPopup = () => {
	const { t } = useTranslation();
	const [userInfo] = useLocalStorage<UserInfos>('USER_INFO', {} as UserInfos);
	const [tabPanelValue, setTabPanelValue] = React.useState(0);

	const ParametersContextValues = React.useContext(ParametersContext);
	const [buttonClickSfx] = useSound(clickButtonSfx, {
		volume: 0.8,
		soundEnabled: ParametersContextValues?.soundEnabled
	});

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		buttonClickSfx();
		setTabPanelValue(newValue);
	};

	const GlobalContextValues = React.useContext(GlobalContext);
	const UserContextValues = React.useContext(UserContext);
	const UIContextValues = React.useContext(UIContext);
	const ApiContextValues = React.useContext(APIContext);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const leaderBoardTeams = await ApiContextValues.getLeaderboardTeams();
				const leaderBoardUsers = await ApiContextValues.getLeaderboardUsers();
				APIGetProfile(ApiContextValues.myProfile.id);

				// Order team and user by score
				GlobalContextValues?.setTeamsLeaderboard!(
					leaderBoardTeams ? leaderBoardTeams.sort((a, b) => b.score - a.score) : []
				);
				GlobalContextValues?.setUsersLeaderboard!(
					leaderBoardUsers
						? leaderBoardUsers.sort((a, b) => (b.score ?? 0) - (a.score ?? 0))
						: []
				);
			} catch (error) {
				// Handle any errors here
				console.error('Error fetching leaderboard data: ', error);
			}
		};

		fetchData();
	}, []);

	// return true if index of my profile is less than top 10
	const isMyProfileInTop10 = () => {
		if (UserContextValues?.myProfile) {
			let myUserIndex = GlobalContextValues?.usersLeaderboard?.findIndex(
				(user) => user.id === UserContextValues?.myProfile?.id
			);
			if (myUserIndex !== undefined && myUserIndex !== -1) {
				return myUserIndex < 10;
			}
		}
		return false;
	};

	// return true if index of my team is less than top 10
	const isMyTeamInTop10 = () => {
		if (UserContextValues?.myTeam) {
			let myTeamIndex = GlobalContextValues?.teamsLeaderboard?.findIndex(
				(user) => user.id === UserContextValues?.myTeam?.id
			);

			if (myTeamIndex !== undefined && myTeamIndex !== -1) {
				return myTeamIndex < 10;
			}
		}
		return false;
	};

	return (
		<PopupContainer
			contentdisplay={ContentDisplayEnum.scrollable}
			title={t('leaderboard_title')}
			open={UIContextValues?.leaderboardVisibility!}
			disableClosable={!UIContextValues.leaderboardInteractive}
			onClickCloseButton={() => {
				UIContextValues?.setLeaderboardVisibility!(false);
			}}
		>
			<Box sx={styles.contentLeaderboard}>
				<Box sx={styles.contentTab}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<Tabs
							sx={styles.switchContainer}
							value={tabPanelValue}
							onChange={handleChange}
							aria-label="basic tabs example"
						>
							<StyledTab sx={styles.switch} label={t('leaderboard_subtitle_team')} />
							<StyledTab
								sx={styles.switch}
								label={t('leaderboard_subtitle_individual')}
							/>
						</Tabs>
					</Box>

					{/* Team rank podium */}

					<TabPanel value={tabPanelValue} index={0}>
						<Grid container columns={12} sx={styles.podiumArea}>
							<Grid sx={styles.podium2} item xs={4}>
								{GlobalContextValues?.teamsLeaderboard![1] && (
									<LeaderboardRank
										podium={true}
										team={GlobalContextValues?.teamsLeaderboard![1]}
										me={
											GlobalContextValues?.teamsLeaderboard![1].id ===
											UserContextValues?.myTeam?.id
										}
									></LeaderboardRank>
								)}
							</Grid>
							<Grid sx={styles.podium1} item xs={4}>
								{GlobalContextValues?.teamsLeaderboard![0] && (
									<LeaderboardRank
										podium={true}
										team={GlobalContextValues?.teamsLeaderboard![0]}
										me={
											GlobalContextValues?.teamsLeaderboard![0].id ===
											UserContextValues?.myTeam?.id
										}
									></LeaderboardRank>
								)}
							</Grid>
							<Grid sx={styles.podium3} item xs={4}>
								{GlobalContextValues?.teamsLeaderboard![2] && (
									<LeaderboardRank
										podium={true}
										team={GlobalContextValues?.teamsLeaderboard![2]}
										me={
											GlobalContextValues?.teamsLeaderboard![2].id ===
											UserContextValues?.myTeam?.id
										}
									></LeaderboardRank>
								)}
							</Grid>
						</Grid>

						<Grid sx={styles.podiumImg} item xs={12}></Grid>
						{/* Team from 4 to 10 */}
						<Stack spacing={2}>
							<Box
								sx={{
									backgroundColor: '#181818',
									borderRadius: '1em',
									border: '1px green solid',
									padding: '1em',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center'
								}}
							>
								<LeaderboardRank
									key={`leaderboard_team_rank_${
										UserContextValues.myTeam?.id
									}_${0}`}
									hideRank={false}
									me={
										UserContextValues.myTeam?.id ===
										UserContextValues?.myTeam?.id
									}
									team={UserContextValues.myTeam}
								></LeaderboardRank>
							</Box>

							{GlobalContextValues?.teamsLeaderboard &&
								GlobalContextValues.teamsLeaderboard
									.slice(3)
									.filter((team) => team.id !== UserContextValues?.myTeam?.id)
									.map((team, index) => (
										<LeaderboardRank
											key={`leaderboard_team_rank_${team.id}_${index}`}
											team={team}
											me={team.id === UserContextValues?.myTeam?.id}
										/>
									))}

							{/* display team if is not in top 10 */}

							{!isMyTeamInTop10() && (
								<LeaderboardRank
									podium={false}
									team={UserContextValues?.myTeam}
									me={true}
								></LeaderboardRank>
							)}
						</Stack>

						{/* User rank podium */}
					</TabPanel>
					<TabPanel value={tabPanelValue} index={1}>
						<Grid container columns={12} sx={styles.podiumArea}>
							<Grid sx={styles.podium2} item xs={4}>
								<LeaderboardRank
									podium={true}
									user={GlobalContextValues?.usersLeaderboard![1]}
									me={
										GlobalContextValues?.usersLeaderboard![1]?.id ===
										UserContextValues?.myProfile?.id
									}
								></LeaderboardRank>
							</Grid>
							<Grid sx={styles.podium1} item xs={4}>
								<LeaderboardRank
									podium={true}
									user={GlobalContextValues?.usersLeaderboard![0]}
									me={
										GlobalContextValues?.usersLeaderboard![0]?.id ===
										UserContextValues?.myProfile?.id
									}
								></LeaderboardRank>
							</Grid>
							<Grid sx={styles.podium3} item xs={4}>
								<LeaderboardRank
									podium={true}
									user={GlobalContextValues?.usersLeaderboard![2]}
									me={
										GlobalContextValues?.usersLeaderboard![2]?.id ===
										UserContextValues?.myProfile?.id
									}
								></LeaderboardRank>
							</Grid>
						</Grid>
						<Grid sx={styles.podiumImg} item xs={12}></Grid>
						{/* Users from 4 to 10 */}
						<Stack spacing={2}>
							{userInfo?.role !== 'evaluator' && (
								<Box
									sx={{
										backgroundColor: '#181818',
										borderRadius: '1em',
										border: '1px green solid',
										padding: '1em',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center'
									}}
								>
									<LeaderboardRank
										key={`leaderboard_user_rank_${
											UserContextValues.myProfile?.id
										}_${0}`}
										hideRank={false}
										me={
											UserContextValues.myProfile?.id ===
											UserContextValues?.myProfile?.id
										}
										user={UserContextValues.myProfile}
									></LeaderboardRank>
								</Box>
							)}
							{GlobalContextValues?.usersLeaderboard &&
								GlobalContextValues.usersLeaderboard
									.slice(3)
									.filter((user) => user.id !== UserContextValues?.myProfile?.id)
									.map((user, index) => (
										<LeaderboardRank
											key={`leaderboard_user_rank_${user.id}_${index}`}
											user={user}
											me={user.id === UserContextValues?.myProfile?.id}
										/>
									))}

							{/* display user if is not in top 10 */}
							{!isMyProfileInTop10() && (
								<LeaderboardRank
									podium={false}
									user={
										userInfo?.role === 'evaluator'
											? UserContextValues?.myProfile.id !== userInfo?.id
												? UserContextValues?.myProfile
												: undefined
											: UserContextValues?.myProfile
									}
									me={true}
								></LeaderboardRank>
							)}
						</Stack>
					</TabPanel>
				</Box>
			</Box>
		</PopupContainer>
	);
};

const styles = {
	// Switch tab
	normalColor: {
		color: '#FFFF'
	},

	selectedColor: {
		color: 'primary.main'
	},

	switch: {
		color: 'primary.main',
		width: '50%',
		'&.Mui-selected': {
			color: '#FFFF',
			backgroundColor: 'primary.main',
			clipPath: 'polygon(14% 0, 100% 0, 100% 100%, 0% 100%))',
			border: '0px !important'
		},
		'&.Mui-focusVisible': {
			backgroundColor: '#d1eaff'
		}
	},
	switchContainer: {
		backgroundColor: '#FFF',
		width: '100%',
		marginBottom: '1em',
		'& .css-wcyces-MuiTabs-indicator': {
			backgroundColor: 'transparent'
		}
	},
	contentTab: {
		padding: '0 !important',
		minwidth: '100%'
	},
	// Podium ranking
	podiumImg: {
		backgroundImage: `url(${podium})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		height: '8em',
		width: '100%',
		// background: 'purple',
		transform: 'translateY(-35%)'
	},
	scorePodium: {
		fontSize: '1em'
	},
	podiumArea: {
		marginTop: '1em',
		marginBottom: '1em',
		display: 'flex',
		justifyContent: 'flex-end',
		// backgroundColor: 'cyan',
		flexWrap: 'nowrap',
		width: '100% !important',
		padding: '0 !important',
		'& .MuiTypography-root': {
			fontSize: '0.8em',
			textAlign: 'center'
		}
	},
	podium1: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		flexWrap: 'no-wrap',
		alignItems: 'center',
		// backgroundColor: 'red',
		padding: '0 !important',
		height: '10em'
	},
	podium2: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		flexWrap: 'no-wrap',
		alignItems: 'center',
		// backgroundColor: 'red',
		padding: '0 !important',
		height: '12em'
	},
	podium3: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		flexWrap: 'no-wrap',
		alignItems: 'center',
		// backgroundColor: 'blue',
		padding: '0 !important',
		height: '12em'
	},
	textPodium: {
		textAlign: 'center !important'
	},
	iconFirstPlace: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '8em',
		backgroundColor: 'primary.main'
	},
	iconSecondPlace: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '6em',
		backgroundColor: 'primary.main'
	},
	iconThirdPlace: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '4em',
		backgroundColor: 'primary.main'
	},
	contentLeaderboard: {
		width: '100%'
	}
};
