import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import SwipeableViews from 'react-swipeable-views';
import { AvatarAccessoryList } from '../../assets/MediaComponents';
import { AvatarAccessory } from '../../contexts/Contexts.types';
import { ArrowButton, Direction } from '../buttons/ArrowButton';

interface ColorSelectionProps {
	// eslint-disable-next-line
	onaccessorychange: (accessoryName: AvatarAccessory) => void;
	label: string | null;
}

export const AccessorySelection = (props: ColorSelectionProps) => {
	const { t } = useTranslation();

	//@ts-ignore
	const swipeableViewsRef = React.useRef(null);
	const [swipeIndex, setSwipeIndex] = React.useState(0);

	const onClickSwipe = React.useCallback(
		(step: number) => {
			if (swipeableViewsRef) {
				//@ts-ignore
				const childCount = swipeableViewsRef.current.containerNode.childElementCount;
				const newIndex = Math.abs(childCount + (swipeIndex + step)) % childCount;

				setSwipeIndex(newIndex);
				props.onaccessorychange(AvatarAccessoryList[newIndex].name);
			}
		},
		[swipeIndex]
	);

	return (
		<Grid container sx={styles.accessory}>
			<Grid item xs={12}>
				<Typography
					variant="h3"
					sx={{
						textAlign: 'center',
						fontSize: {
							xs: '0.9em',
							sm: '1em',
							md: '1em'
						}
					}}
				>
					{props.label}
				</Typography>
			</Grid>

			<Grid container sx={styles.accessoryContainer}>
				<Grid item xs={2}>
					<ArrowButton
						arrowdirection={Direction.Left}
						onClick={() => {
							onClickSwipe(-1);
						}}
					/>
				</Grid>

				<Grid item xs={8} sx={styles.accessoryChoice}>
					<SwipeableViews
						style={{
							display: 'flex',
							alignItems: 'center',
							flexWrap: 'nowrap',
							justifyContent: 'center',
							flexDirection: 'row',
							// borderRadius: '100%',
							// height: "10em",
							// width: "10em",
							textAlign: 'center'
						}}
						ref={swipeableViewsRef}
						index={swipeIndex}
					>
						{AvatarAccessoryList.map((accessory) => {
							return (
								<Typography textAlign={'center'} key={accessory.name}>
									{t(accessory.name)}
								</Typography>
							);
						})}
					</SwipeableViews>
				</Grid>
				<Grid item xs={2}>
					<ArrowButton
						arrowdirection={Direction.Right}
						onClick={() => {
							onClickSwipe(1);
						}}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

const styles = {
	accessory: {
		display: 'flex',
		direction: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%'
		// backgroundColor: 'purple'
	},
	accessoryContainer: {
		display: 'flex',
		direction: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		marginTop: {
			xs: '1vh',
			sm: '1em',
			md: '1em'
		}
	},
	accessoryChoice: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',

		// width: "10em",
		// backgroundColor: 'white',
		'& svg': {
			height: '100%'
		}
	},

	accessoryPreview: {
		height: '10em',
		width: '10em',
		backgroundColor: 'white'
	}
};
