import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { LoaderFallback } from '../components/LoaderFallback';
import { APIContext } from '../contexts/API/APIContext';
import { APISession } from '../contexts/Contexts.model';
import { UserInfos } from '../contexts/Contexts.types';
import { UIContext } from '../contexts/UIContext';

const introductionRoutes = ['/introduction-video', '/profile/avatar', '/profile/BIO'];
const conclusionRoutes = ['/ending-summary', '/ending-summary-video', '/ending-summary-speech'];

const AuthGuard: React.FC = () => {
	const UIContextValues = useContext(UIContext);

	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [sessionData, setSessionData] = useState<APISession>();

	const [userInfo, setUserInfo] = useLocalStorage<UserInfos>('USER_INFO', {} as UserInfos);

	const APIContextValues = useContext(APIContext);

	useEffect(() => {
		const checkAuthentication = async () => {
			const response = await axios.get('/api/loggedIn');
			let { isAuthenticated, userInfo } = response.data;

			setUserInfo(userInfo);
			setIsAuthenticated(isAuthenticated);

			if (!userInfo) {
				setIsLoading(false);
				return;
			}

			// ----------------- SESSIONS -----------------
			let sessionData: APISession | undefined = undefined;

			sessionData = await APIContextValues.getSession(userInfo?.id);

			if (sessionData) {
				setSessionData(sessionData);

				UIContextValues.setEndingSummaryTrigger?.(sessionData.completion === 'COMPLETED');
			}

			// ----------------- IS AVATAR CREATED -----------------

			if (userInfo === null || !userInfo?.id) return;
			const myProfile = await APIContextValues.getProfile(
				userInfo.id,
				true,
				sessionData?.sessionId
			);

			if (myProfile) {
				// team
				APIContextValues.setIsAvatarSet(myProfile.introductionFlag);
				await APIContextValues.getTeam(myProfile.teamId, true);
			}

			setIsLoading(false);
		};

		checkAuthentication();
	}, []);

	const currentRoute = window.location.pathname;

	const maintainer = userInfo?.role?.toUpperCase() === 'MONITOR';
	if (isLoading) {
		return <LoaderFallback />;
	}

	if (!userInfo) {
		return <Navigate to="/login" />;
	}

	if (
		userInfo?.active !== true ||
		(userInfo?.role !== 'member' &&
			userInfo?.role !== 'monitor' &&
			userInfo?.role !== 'evaluator')
	) {
		return <Navigate to="/login/invalid" />;
	}

	if (maintainer && !APIContextValues.isAvatarSet) {
		const currentRoute = window.location.pathname;

		if (introductionRoutes.includes(currentRoute)) {
			return <Outlet />;
		}

		return <Navigate to="/profile/avatar" />;
	} else if (!maintainer && (!isAuthenticated || !sessionData)) {
		if (!APIContextValues.myProfile.id) {
			UIContextValues?.setErrorPopupText!({
				title: 'SESSION NOT STARTED YET!',
				description: ' Please come back later to start your EXPLORE session.'
			});
			UIContextValues?.setErrorPopupVisibility?.(true);
		}
		return <Navigate to="/login" />;
	} else if (!maintainer && sessionData?.completion === 'COMPLETED') {
		if (conclusionRoutes.includes(currentRoute)) {
			return <Outlet />;
		}

		return <Navigate to="/ending-summary-video" />;
	} else if (!maintainer && sessionData?.completion === 'SCHEDULED') {
		UIContextValues?.setErrorPopupText!({
			title: 'SESSION NOT STARTED YET!',
			description: ' Please come back later to start your EXPLORE session.'
		});
		UIContextValues?.setErrorPopupVisibility?.(true);
		return <Navigate to="/login" />;
	}

	if (!APIContextValues.isAvatarSet) {
		const currentRoute = window.location.pathname;

		if (introductionRoutes.includes(currentRoute)) {
			return <Outlet />;
		}

		return <Navigate to="/introduction-video" />;
	}

	// redirect to home if is in  routes introduction or conclusion
	if ([...introductionRoutes, ...conclusionRoutes].includes(currentRoute)) {
		return <Navigate to="/" />;
	}
	return <Outlet />;
};

export default AuthGuard;
